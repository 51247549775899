/* eslint-disable */
(function () {
  function url(endpoint) {
    var passThroughDomains = [
      'meetings.hubspot.com',
      'meetings.hubspotqa.com',
      'meetings-eu1.hubspot.com',
      'meetings-ap1.hubspot.com',
      'meetings-na2.hubspot.com',
      'meetings-na3.hubspot.com',
      'meetings-eu1.hubspotqa.com',
      'app.hubspot.com',
      'app.hubspotqa.com',
      'app-eu1.hubspot.com',
      'app-ap1.hubspot.com',
      'app-na2.hubspot.com',
      'app-na3.hubspot.com',
      'app-eu1.hubspotqa.com'
    ]
    var canUsePassthrough = passThroughDomains.filter(function(domain) {
      return window.location.hostname.indexOf(domain) > -1
    }).length > 0

    var fullUrl = window.quickFetch.getApiUrl(endpoint, canUsePassthrough);

    // enables QA custom domains (hs-sitesqa.com), since getApiUrl won't know that we need to hit QA APIs otherwise
    var needsEnvOverride = window.location.hostname.indexOf('hs-sitesqa') > -1;
    if (needsEnvOverride) {
      fullUrl = fullUrl.replace(/^https:\/\/(api|app)(-[\w\d]*)?\.hubspot/, '$&qa');
    }

    // enables apipassthrough for meetings-* domains and custom domains
    // quickfetch does not support non-standard (api|local|app)
    // https://git.hubteam.com/HubSpot/quick-fetch/blob/master/static/js/index.js#L274
    var needsHubletOverride =
      window.location.hostname.indexOf('meetings-') > -1 ||
      window.hubspot.meetingsHubletOverride !== undefined
    if (needsHubletOverride) {
      fullUrl = fullUrl.replace(/^(https:\/\/)(api|app)(-[\w\d]*)?\./, `$1$2-${window.hubspot.meetingsHubletOverride}.`);
    }

    return fullUrl;
  }


/** The Java agent fails to parse the rate if it is not passed as a float */
 function rateToString(rate) {
  return Number(rate).toFixed(1)
 }

function buildFailureInjectionHeader(injectionConfig) {
  return `${injectionConfig.type};${injectionConfig.scope};ABORT;1;${rateToString(
      injectionConfig.rate
    )};${injectionConfig.sleep}`;
}
/**
 * Adds Failure Injection Headers in QA only via localStorage flags
 * For specific slugs that are prefixed with meetings-booking-failure
 */
function maybeAddFailureInjectionHeader() {
    const isQA = window.location.hostname.includes('hubspotqa.com');
    const isFailureSlug = window.location.pathname.includes('meetings-booking-failure');
    const HTTP_FAILURE_INJECTION_KEY = 'HTTP_FAILURE_INJECTION';

    if (isQA && isFailureSlug) {
      try {
        const httpFailureInjectionValue =
          window.localStorage.getItem(HTTP_FAILURE_INJECTION_KEY) || '';
        const injectionConfig = JSON.parse(
          httpFailureInjectionValue
        );
        const headerValue = buildFailureInjectionHeader(injectionConfig);

        return {
          header: 'x-hubspot-failure-injection',
          value: headerValue
        };
      } catch (err) {
        console.log(err);
      }
    }

    return {}
  }
  /**
   * Additional header objects should be added to the list
   * in order for it be used by the quick-fetch request
   * @returns
   */
  function addExtraHeaders() {
    const headersList = [];
    const failureInjectionHeader = maybeAddFailureInjectionHeader();
    if (failureInjectionHeader.header) {
      headersList.push(failureInjectionHeader)
    }
    return headersList;
  }

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = cookie.substring(name.length + 1);
          break;
        }
      }
    }
    return cookieValue;
  }

  function getPageQueryParams() {
    var queryParamArray = window.location.search.split('?');
    if (queryParamArray.length !== 2) {
      return {};
    }
    return queryParamArray[1].split('&').reduce(function (map, pairString) {
      var pairArray = pairString.split('=');
      var toAdd = {};
      toAdd[pairArray[0]] = decodeURIComponent(pairArray[1]);
      return Object.assign({}, map, toAdd);
    }, {});
  }

  function stringifyParams(params) {
    return Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  }

  function getHubSpotUtk() {
    return getCookie('hubspotutk');
  }

  function addParentCosPageUrl(params) {
    var incomingQueryParams = getPageQueryParams();
    if (!incomingQueryParams || !incomingQueryParams.parentPageUrl) {
      return params;
    }

    return Object.assign({}, params, {
      parentCosPageUrl: incomingQueryParams.parentPageUrl,
    });
  }

  function addABTestingParams(params) {
    var incomingQueryParams = getPageQueryParams();
    if (!incomingQueryParams || !incomingQueryParams.ab && !incomingQueryParams.abStatus && !incomingQueryParams.contentId) {
      return params;
    }
    // TODO: Check with Zac for validation of values here
    return Object.assign({}, params, {
        ab: incomingQueryParams.ab,
        abStatus: incomingQueryParams.abStatus,
        contentId: incomingQueryParams.contentId
    })
  }

  function addPrefilledEmail(params) {
    var incomingQueryParams = getPageQueryParams();
    if (!incomingQueryParams || !incomingQueryParams.email) {
      return params;
    }

    return Object.assign({}, params, {
      email: incomingQueryParams.email,
    });
  }

  function getUtk() {
    var incomingQueryParams = getPageQueryParams();
    if (!incomingQueryParams || !incomingQueryParams.parentHubspotUtk) {
      return getHubSpotUtk();
    }

    return incomingQueryParams.parentHubspotUtk;
  }

  function getMeetingChangeId() {
    var incomingQueryParams = getPageQueryParams();
    if (!incomingQueryParams || !incomingQueryParams.rescheduleId) {
      return null
    }
    return incomingQueryParams.rescheduleId;
  }

  function addMeetingChangeIdParam(params) {
    var meetingChangeId = getMeetingChangeId();
    if (!meetingChangeId) {
      return params;
    }
    return Object.assign({}, params, {
      meetingChangeId
    })
  }

  function sanitizePathname(pathname) {
    var lastIndex = pathname.length - 1;

    if (pathname.charAt(lastIndex) === '/') {
      return pathname.substring(0, lastIndex);
    }

    return pathname;
  }
  function getSlug() {
    // If the url contains "/meetings/" (old domain pattern, custom domain pattern)
    // then we should split pathname on 'meetings' to grab the slug
    // Otherwise, we are on the domain pattern meetings.hubspot.com
    // and the slug is just the pathname with leading '/' removed
    if (window.location.pathname.indexOf('/meetings/') > -1) {
      return sanitizePathname(window.location.pathname).split('/meetings/')[1];
    }
    return sanitizePathname(window.location.pathname).slice(1);
  }

  var slug = getSlug();

  var params = {
    slug: slug,
    now: new Date().getTime(),
    includeInactiveLink: true,
    location: window.location.host,
    hubspotUtk: getUtk(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  params = addMeetingChangeIdParam(params)
  params = addParentCosPageUrl(params);
  params = addPrefilledEmail(params);
  params = addABTestingParams(params)

  window.quickFetch.makeEarlyRequest('book-info', {
    url: url(
      '/meetings-public/v3/book?' +
      stringifyParams(params)
    ),
    type: 'GET',
    dataType: 'json',
    timeout: 12000,
    withCredentials: false,
    noPortalId: true,
    extraHeaders: addExtraHeaders()
  });
})();
/* eslint-enable */


